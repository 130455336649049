import { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ShopSidebar from "../../wrappers/product/ShopSidebar";
import ShopTopbar from "../../wrappers/product/ShopTopbar";
import ShopProducts from "../../wrappers/product/ShopProducts";
import axios from "axios";
import PulseLoader from "../../components/ImageInputFiled/PulseLoader";
import { setRawProducts } from "../../store/slices/product-slice";
import { useDispatch } from "react-redux";
const API_URL = process.env.REACT_APP_API_HOST;
const Products = () => {
    const [layout, setLayout] = useState("grid three-column");
    const [showFilter, setShowFilter] = useState(false);
    const [products, setProducts] = useState([]);
    const [count, setCount] = useState(0);
    const dispatch = useDispatch();
    let { pathname } = useLocation();
    const [searchedProducts, setsearchedProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    const query = new URLSearchParams(useLocation().search);
    const category = query.get("category");
    const brand = query.get("brand");
    const carrier = query.get("carrier");
    const model = query.get("model");
    const search = query.get("search");
    const breadCrumbRoute = category ? category : brand ? brand : carrier ? carrier : model ? model : search ? search : "All Products";
    const getLayout = (layout) => {
        setLayout(layout);
    };

    useEffect(() => {
        const route = category
            ? `/products/search/filter?category=${category}&page=1&limit=40`
            : brand
                ? `/products/search/filter?brand=${brand}&page=1&limit=40`
                : carrier
                    ? `/products/search/filter?carrier=${carrier}&page=1&limit=40`
                    : model
                        ? `/products/search/filter?model=${model}&page=1&limit=40`
                        : "/products/search/filter?page=1&limit=40";
        const fetchProducts = async () => {
            setLoading(true);
            const { data } = await axios.get(`${API_URL}${route}`);
            setProducts(data.products);
            setCount(data.products.length);
            dispatch(setRawProducts(data.products));
            setLoading(false);
        };
        fetchProducts();
        // eslint-disable-next-line
    }, [category, brand, carrier]);

    return (
        <Fragment>
            <SEO
                titleTemplate="Remax | Shop"
                description="Deal in all kind of accessories."
            />

            <LayoutOne
                headerTop="visible"
                headerContainerclassName="container-fluid"
                headerPaddingclassName="header-padding-1"
            >
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        {
                            label: breadCrumbRoute, path: process.env.PUBLIC_URL + pathname
                        }
                    ]}
                />
                {loading ? (
                    <div className="product-loading">
                        <PulseLoader />
                    </div>
                ) : products || searchedProducts ? (
                    <>
                        <div className="shop-area pt-95 pb-100">
                            <div className="container-fluid">
                                {/* {showFilter && <span className="side-filters-overalay"></span>}

                                <div
                                    className={
                                        showFilter ? "side-filters active" : "side-filters"
                                    }
                                >
                                </div> */}
                                <div className="row">
                                    <div className="col-lg-12 order-1 order-lg-2">
                                        <div className="d-flex justify-content-between">
                                            <Link
                                                to="/shop"
                                                className="filter-btn"
                                                onClick={() => setShowFilter(true)}
                                            >
                                                Filters
                                            </Link>
                                            {/* <button className="filter-btn" onClick={() => { }}>Clear Filters</button> */}
                                            <ShopTopbar
                                                getLayout={getLayout}
                                                productCount={count}
                                                sortedProductCount={
                                                    search ? searchedProducts?.length : products?.length
                                                }
                                                products={search ? searchedProducts : products}
                                            />
                                        </div>
                                        <div className="container">
                                            <ShopProducts
                                                layout={layout}
                                                products={search ? searchedProducts : products}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <h1>No Product Found</h1>
                    </>
                )}
            </LayoutOne>
        </Fragment>
    );
};

export default Products;
