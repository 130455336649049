import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import ShopCategoriesFilter from "./ShopCategoriesFilter";
import ShopPrice from "./ShopPrice";
import { setmodels } from "../../store/slices/model-slice";

const ShopSidebar = ({
  sideSpaceClass,
  filter,
  setFilter,
  setPriceRange,
  priceRange,
}) => {
  // const { categories } = useSelector((state) => state.category);
  // const { brands } = useSelector((state) => state.brand);
  // const { carriers } = useSelector((state) => state.carrier);
  // const { models } = useSelector((state) => state.models);
  // const dispatch = useDispatch()

  return (
    <>
      <div className="sidebar-style-new">
        <div className="d-flex justify-content-between align-items-center mb-20">
          <div className="sidebar-title">Refine Results</div>
        </div>
        <div className={clsx("sidebar-style mb-30", sideSpaceClass)}>

          <ShopCategoriesFilter
            setFilter={setFilter}
            filter={filter}
          />

        </div>
      </div>
    </>
  );
};

ShopSidebar.propTypes = {
  getSortParams: PropTypes.func,
  products: PropTypes.array,
  sideSpaceClass: PropTypes.string,
};

export default ShopSidebar;
