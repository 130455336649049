import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useState } from "react";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";

const ShopCategoriesFilter = ({ setFilter, filter }) => {
  const MotionButton = motion.button;
  const [openCategories, setOpenCategories] = useState({});
  const { nav } = useSelector((state) => state.navbar);

  const toggleOpen = (key) => {
    setOpenCategories((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleFilterClick = (type, id) => {
    setFilter((prev) => ({
      ...prev,
      [type]: prev[type].includes(id) ? prev[type].filter((item) => item !== id) : [...prev[type], id],
    }));
  };

  return (
    <div className="sidebar-widget">
      <div className="sidebar-widget-list mt-30">
        <ul>
          {nav.map((brand) => (
            <li key={brand.slug}>
              <div className="sidebar-widget-list-left">
                <MotionButton
                  onClick={() => toggleOpen(brand.slug)}
                  className="ps-2 pb-1"
                >
                  <p className="mb-0 fs-5">{brand.name}</p>
                  <motion.span>
                    {openCategories[brand.slug] ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
                  </motion.span>
                </MotionButton>
                <AnimatePresence>
                  {openCategories[brand.slug] && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="submenu-container"
                    >
                      <ul className="ml-2 my-2">
                        {brand.brands.map((category) => (
                          <li key={category.slug} className="ms-3 pb-2">
                            <MotionButton
                              onClick={() => toggleOpen(category.slug)}
                              className="ps-2"
                            >
                              <p className="mb-0 fs-6">{category.name}</p>
                              <motion.span>
                                {openCategories[category.slug] ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
                              </motion.span>
                            </MotionButton>
                            <AnimatePresence>
                              {openCategories[category.slug] && (
                                <motion.div
                                  initial={{ height: 0, opacity: 0 }}
                                  animate={{ height: "auto", opacity: 1 }}
                                  exit={{ height: 0, opacity: 0 }}
                                  transition={{ duration: 0.2 }}
                                  className="submenu-container"
                                >
                                  <ul className="ml-4 my-2">
                                    {category.models.map((item) => (
                                      <li key={item.slug}>
                                        <Link
                                          onClick={() => handleFilterClick("models", item.slug)}
                                          className={filter.models.includes(item.slug) ? "active" : ""}
                                        >
                                          <span className={`checkmark `} />
                                          {item.name}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </motion.div>
                              )}
                            </AnimatePresence>
                          </li>
                        ))}
                      </ul>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

ShopCategoriesFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
};

export default ShopCategoriesFilter;
