import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const MegaMenu = () => {

    const { nav } = useSelector(state => state.navbar)
    const data = nav?.map((brand) => {
        return {
            brand: brand.name,
            slug: brand.slug,
            categories: brand.brands.map((category) => {
                return {
                    name: category.name,
                    slug: category.slug,
                    items: category.models.map((item) => {
                        return {
                            name: item.name,
                            slug: item.slug
                        }
                    })
                }
            })
        }
    })

    return (
        <header className="header">
            <div className="container">
                <div className="row v-center">
                    <div className="header-item item-center">
                        <div className="menu-overlay"></div>
                        <nav className="menu">
                            <div className="mobile-menu-head">
                                <div className="go-back"><i className="fa fa-angle-left"></i></div>
                                <div className="current-menu-title"></div>
                                <div className="mobile-menu-close">&times;</div>
                            </div>
                            <ul className="menu-main">

                                {
                                    data.map((brand, index) => (
                                        <li className="menu-item-has-children" key={index}>
                                            <Link to={`/shop/${brand.slug}`}>{brand.brand}&nbsp;<i className="fa fa-angle-down"></i></Link>
                                            <div className="sub-menu mega-menu mega-menu-column-4">
                                                <div className="list-item">
                                                    {
                                                        brand.categories.map((category, index) => (
                                                            <div key={index}>
                                                                <h4 className="title">
                                                                    <Link to={`/shop/${brand.slug}/${category.slug}`}>{category.name}</Link></h4>
                                                                <div className="column">
                                                                    <ul>
                                                                        {
                                                                            category.items.map((item, index) => (
                                                                                <li key={index}>
                                                                                    <Link to={`/shop/${brand.slug}/${category.slug}/${item.slug}`}>{item.name}</Link>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }
                                <li className='menu-item-has-children'>
                                    <Link to='/clearance'>Clearance</Link>
                                </li>
                                {/* <li className='menu-item-has-children'>
                                    <Link to='/contact'>Contact Us</Link>
                                </li> */}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header >
    );
};

export default MegaMenu;
